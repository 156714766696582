<template>
  <div class="management">
    <!-- <el-card class="filter-card">
      <div slot="header" class="clearfix">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-card> -->

    <!-- 配置表单 -->
    <el-card class="manage-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
          <el-button :disabled="user.role_type > 2"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="onHandleAdd"
          >添加</el-button>
        </div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column width="100"
          prop="user_name"
          label="角色名称">
        </el-table-column>
        <el-table-column width="100" align="center"
          prop="nick_name"
          label="昵称">
        </el-table-column>      
        <el-table-column width="100" align="center"
          prop="role_type"
          label="角色类型">
          <template slot-scope="scope">
            {{ resultRole(scope.row.role_type) }}
          </template>
        </el-table-column>    
        <el-table-column
          prop="email"
          width="150"
          label="邮箱">
        </el-table-column>    
        <el-table-column width="100" align="center"
          prop="phonenumber"
          label="手机号码">
        </el-table-column>    
        <el-table-column width="50" align="center"
          prop="sex"
          label="性别">
          <template slot-scope="scope">
            {{ scope.row.sex === "0" ? "男" : "女" }}
          </template>
        </el-table-column>    
        <el-table-column  width="50" align="center"
          prop="status"
          label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === "0" ? "正常" : "禁用" }}
            <!-- <el-switch v-model="scope.row.status=='0'?true:false" /> -->
          </template>
        </el-table-column>       
        <el-table-column
          prop="del_flag"  width="80" align="center"
          label="删除标志">
          <template slot-scope="scope">
            {{ scope.row.del_flag === "0" ? "存在" : "删除" }}
          </template>
        </el-table-column>          
        <el-table-column width="100" align="center"
          prop="create_by"
          :formatter="formatterEmpty"
          label="创建者">
        </el-table-column>    
        <el-table-column width="100" align="center"
          prop="update_by"
          :formatter="formatterEmpty"
          label="更新者">
        </el-table-column>   
        <el-table-column
          prop="update_time" width="180" align="center"
          :formatter="formatterDateTime"
          label="更新时间">
        </el-table-column>  
        <el-table-column width="150" align="center"
          prop="expiration_timestamp"
          :formatter="formatterDateTime"
          label="有效期时间">
        </el-table-column>  
        <el-table-column
          prop="remark" align="center"
          :formatter="formatterEmpty"
          label="备注">
        </el-table-column>    
        <el-table-column label="操作" width="125" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button :disabled="(scope.row.role_type === 3 || scope.row.role_type === 0)  && user.user_name !== 'admin'" v-if="user.user_name !== scope.row.user_name && scope.row.del_flag == '0'"
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditUserInfo(scope.row)"
            ></el-button>
            <el-button :disabled="(scope.row.role_type === 3 || scope.row.role_type === 0) && user.user_name !== 'admin'" v-if="user.user_name !== scope.row.user_name && scope.row.del_flag == '0'"
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteUserInfo(scope.row.user_name)"
            ></el-button>
            <el-button :disabled="(scope.row.role_type === 3 || scope.row.role_type === 0) && user.user_name !== 'admin'" v-if="user.user_name !== scope.row.user_name && scope.row.del_flag == '0'"
              size="mini"
              type="info"
              circle
              icon="el-icon-key"
              @click="onResetUserPwd(scope.row.user_name)"
            ></el-button>
            <el-button :disabled="(scope.row.role_type === 3 || scope.row.role_type === 0)  && user.user_name !== 'admin'" v-if="user.user_name !== scope.row.user_name && scope.row.del_flag != '0'"
              size="mini"
              type="info"
              round
              icon="el-icon-key"
              @click="onActivateUser(scope.row.user_name)"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="700px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色名称: " prop="user_name">
              <el-input :disabled="operType" maxlength="32" v-model="addForm.user_name" placeholder="请输入角色名称" autocomplete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="!operType" label="用户密码: " prop="password">
              <el-input :disabled="operType" type="password" maxlength="20" show-password v-model="addForm.password" placeholder="请输入用户密码" autocomplete="off"/>
            </el-form-item>
          </el-col>
        </el-row>   
        
        <el-form-item label="昵称: " prop="nick_name">
          <el-input  maxlength="32" v-model="addForm.nick_name" placeholder="请输入角色昵称" />
        </el-form-item>

        <el-form-item label="角色类型: ">
          <el-radio-group v-model="addForm.role_type">
              <el-radio :label="0" :disabled="user.role_type !== 0">超级管理员</el-radio>
              <el-radio :label="1">管理员</el-radio>
              <el-radio :label="4">运营角色</el-radio>
              <el-radio :label="2">普通角色</el-radio>
              <el-radio :label="3" :disabled="user.role_type !== 0">只读角色</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱: " prop="email">
              <el-input maxlength="64" v-model="addForm.email" placeholder="请输入邮箱" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="手机号: " prop="phonenumber">
              <el-input maxlength="11" v-model="addForm.phonenumber" placeholder="请输入手机号" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
           <el-form-item label="性别: " prop="sex">
              <el-radio-group v-model="addForm.sex">
                <el-radio :label="0">男</el-radio>
                <el-radio :label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号状态: " prop="status">
              <el-radio-group v-model="addForm.status">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="有效期: " prop="validity">
              <el-date-picker
                v-model="addForm.validity"
                type="datetime"
                :picker-options="expireTimeOption"
                placeholder="选择截止有效期">
              </el-date-picker>
            </el-form-item>
          </el-col>       
        </el-row>

        <el-form-item label="备注: " prop="remark">
          <el-input type="textarea" autosize v-model="addForm.remark" placeholder="请输入备注" style="width: 320px" />
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddUserInfo" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getUserProfile, getUserList, addUserInfo, updateUserInfo, delUserInfo, resetUserPwd, activateUser} from "@/api/user"
  import {formatDate} from "@/utils/date"

  export default {
    name: "RoleManagement",
    data() {
      return {
        user: {},
        form: {
          name: ''
        },
        title: "",     // 弹出层标题
        loading: true, // 表单数据加载中
        data: [],      // 用户列表
        page: 1,       // 当前页码
        pageSize: 20,  // 每页大小
        dialogFormVisible: false, // 控制对话框的显示隐藏
        operType: false, // 操作类型
        // 添加配置信息
        addForm:{
          user_name: "",  // 角色名称
          nick_name: "",  // 角色昵称
          role_type: 2,   // 角色类型
          email: "",      // 邮箱
          phonenumber: "",// 手机号
          password: "",   // 密码
          sex:0,          // 性别 
          status: 0,      // 角色状态
          validity: "",   // 有效期
          remark: "",     // 备注
        },
        addFormRules: {
          user_name: [{required: true, trigger: 'blur', message: "角色名称不能为空"}],
          password: [
            { required: true, message: "用户密码不能为空", trigger: "blur" },
            { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
          ],
          email: [
            { required: true, message: "邮箱不能为空", trigger: "blur" },            
            {
              type: "email",
              message: "'请输入正确的邮箱地址",
              trigger: ["blur", "change"]
            }
          ],
          phonenumber: [
            { required: true, message: "手机号不能为空", trigger: "blur" },
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: "请输入正确的手机号码",
              trigger: "blur"
            }
          ]
        },
        expireTimeOption: {
          disabledDate(date) {
            return date.getTime() < Date.now() - 8.64e7
          }
        }
      }
    },
    created() {
      this.loadUserList()
      this.loadUserProfile()
    },

    methods: {
      // 表单重置
      reset() {
        this.addForm = {
          user_name: "",  // 角色名称
          nick_name: "",  // 角色昵称
          role_type: 2,   // 角色类型
          email: "",      // 邮箱
          phonenumber: "",// 手机号
          password: "",   // 密码
          sex:0,          // 性别 
          status: 0,      // 角色状态       
          validity: "",   // 有效期
          remark: "",     // 备注
        }
      },
      // 获取用户资料, 其他所有接口都需要授权才能请求使用
      loadUserProfile() {
        getUserProfile().then(res => {
          this.user = res.data
        })
      },
      loadUserList(page = 1) {
        this.loading = true
        getUserList({
           page,
          "count": this.pageSize,
        }).then(res => {
          this.data = res.data
          //  关闭加载中
          this.loading = false
        }).catch(err => {
         this.loading = false
        })
      },
      /**
       *  新增产品配置信息
       */
      onHandleAdd() {
        this.reset()
        this.operType = false
        this.dialogFormVisible = true
        this.title = "添加角色信息"
      },
      /**
       * 查询
       */
      onSearch() {
        this.loadUserList()
      },
      /**
       *  分页按钮响应交互
       */
      onCurrentChange(page) {
        this.loadUserList(page)
      },
      /**
     * 添加用户信息
     */
      onAddUserInfo() {
        this.$refs["addFormRef"].validate(valid => {
          if (valid) {
            this.loading = true

            if (!this.operType) {
              addUserInfo({
                  "user_name": this.addForm.user_name,
                  "password": this.addForm.password,
                  "nick_name": this.addForm.nick_name,
                  "role_type": this.addForm.role_type,
                  "email": this.addForm.email,  
                  "phonenumber": this.addForm.phonenumber,               
                  "sex": this.addForm.sex.toString(),
                  "status": this.addForm.status.toString(),
                  "expiration_timestamp": this.addForm.validity === null ? "" : new Date(this.addForm.validity).getTime()/1000 ,
                  "remark": this.addForm.remark,                               
                }).then(res => {
                //  添加成功，刷新列表
                this.loadUserList(this.page)
                this.loading = false
                this.$message({
                  message: '恭喜您，添加成功',
                  type: 'success'
                });
                this.dialogFormVisible = false
              }).catch(err => {
                this.loading = false
                this.$message({
                  message: '不好意思，添加失败了哟，请重试',
                  type: 'warning'
                });
              })
            } else {
              updateUserInfo({
                  "user_name": this.addForm.user_name,
                  "nick_name": this.addForm.nick_name,
                  "role_type": this.addForm.role_type,
                  "email": this.addForm.email,  
                  "phonenumber": this.addForm.phonenumber,               
                  "sex": this.addForm.sex.toString(),
                  "status": this.addForm.status.toString(),
                  "expiration_timestamp": this.addForm.validity === null ? "" : new Date(this.addForm.validity).getTime()/1000 ,
                  "remark": this.addForm.remark,  
              }).then(res => {
                //  添加成功，刷新列表
                this.loadUserList(this.page)
                this.loading = false
                this.$message({
                  message: '恭喜您，修改成功',
                  type: 'success'
                });
                this.dialogFormVisible = false
              }).catch(err => {
                 this.loading = false
                this.$message({
                  message: '不好意思，修改失败了哟，请重试',
                  type: 'warning'
                });
              })
            }
          }
        })
      },
      /**
       * 删除用户信息
       */
      onDeleteUserInfo(user_name) {
        this.$confirm('确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUserInfo(user_name).then(res => {
            // 删除成功
            this.loadUserList(this.page)
          })
        }).catch(() => {});
      },      
      /**
       *  修改用户信息
       */
      onEditUserInfo(data) {
        this.reset() 
        this.operType = true
        this.dialogFormVisible = true
        this.addForm.user_name = data.user_name
        this.addForm.password = null
        this.addForm.nick_name = data.nick_name
        this.addForm.role_type = data.role_type
        this.addForm.email = data.email
        this.addForm.phonenumber = data.phonenumber
        this.addForm.sex = parseInt(data.sex)
        this.addForm.status = parseInt(data.status)
        this.addForm.validity = data.expiration_timestamp === 0 ? "" : new Date(data.expiration_timestamp * 1000).toLocaleString('chinese',{hour12:false})
        this.addForm.remark = data.remark
        this.title = "修改用户信息"
      },
      /** 重置密码操作 */
      onResetUserPwd(user_name) {
        this.$prompt('请输入"' + user_name + '"的新密码', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: /^.{5,20}$/,
          inputErrorMessage: "用户密码长度必须介于 5 和 20 之间",
        }).then(({ value }) => {
            console.log(user_name,value)
            resetUserPwd(user_name, value).then(res => {
              this.msgSuccess("修改成功，新密码是：" + value);
            });
          }).catch(() => {});
      },
      /** 激活用户操作 */
      onActivateUser(user_name) {
        activateUser(user_name).then(res => {
          this.loadUserList()
          this.msgSuccess("账号"+ user_name +"激活成功");
        });
      },
      //角色名称转换
      resultRole(v){
        switch (Number(v)) {
          case 0:
            return '超级管理员'
          case 1:
            return '管理员'
          case 2:
            return '普通用户'
          case 3:
            return '只读角色'
          case 4:
            return '运营角色'
          default:
            return '未知'
        }
      },
      //为空转换
      formatterEmpty(row, column, v){
        if (v || (v === 0)) return v
        return "-"
      },
      //时间转换
      formatterDateTime(row, column, v){
        if (!v) return "-"
        if(String(v).length === 10) v = v * 1000
        let date = new Date(v)
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      }    
    }
  }
</script>

<style scoped>

  .list-table {
    margin-bottom: 10px;
  }
</style>
